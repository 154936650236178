import { ADDRESS_FLAT_REGEXP, ADDRESS_POSTAL_CODE_REGEXP } from '../constants';

/** @deprecated Часть заявок в FB сохранилась с ошибкой в формировании адресов (д дом), в будущем можно убрать */
export const fixAddressHouseIssue = (address?: string) => {
    return (address || '').replace('д дом ', 'д ');
};

export const removeAllStreetPartsAfterHouse = (address?: string) => {
    if (address === undefined) {
        return '';
    }

    const arr = address.split(',');
    const i = arr.findIndex((v) => /^(д|уч)\s/i.test(v.trim()));

    if (i === -1) {
        return address;
    } else {
        return arr.slice(0, i + 1).join();
    }
};

/** Убирает почтовый индекс из начала адреса */
export const removeZipCodeFromAddress = (address?: string) => {
    return (address || '').replace(ADDRESS_POSTAL_CODE_REGEXP, '');
};

export const getApartmentFromAddress = (address?: string) => {
    return (address || '').split(ADDRESS_FLAT_REGEXP)[1];
};

export const getAddressString = (street: string, apartment: string) =>
    `${street}${apartment ? `, кв ${apartment}` : ''}`.replace(ADDRESS_POSTAL_CODE_REGEXP, '');

/** Для совместимости с заявками со старым форматом адресов из FB */
export const buildOldAddress = (street: string, house: string) => {
    return `${street}, ${house.replace(/^\D*(?=(\d))/, 'д ')}`;
};

export const getStreetAndHouseFromAddress = (address?: string) => {
    const [street, house] = (address || '').split(/,\s?(?=[^,]*$)/);
    const regexp = /(д(ом)?|уч(асток)?|стр?)\.?\s/i;
    const housePart = regexp.exec(house)?.[0];

    if (!housePart) {
        return {
            street: address || '',
            house: '',
        };
    }
    const houseNumber = house.split(housePart)?.[1];
    return {
        street,
        house,
        houseNumber,
    };
};
